/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.parallelograms-wrapper .parallelogram[data-v-43e1e238] {
  position: absolute;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  transform: skewY(20deg);
}
.parallelograms-wrapper .parallelogram-1[data-v-43e1e238] {
  background: #f7a600;
  height: 200px;
  width: 115px;
  left: 7%;
  top: 40px;
}
.parallelograms-wrapper .parallelogram-2[data-v-43e1e238] {
  background: #ffca5e;
  height: 100px;
  width: 60px;
  left: 25%;
  top: 50px;
}
.parallelograms-wrapper .parallelogram-3[data-v-43e1e238] {
  background: #023aae;
  height: 100px;
  width: 60px;
  left: 80%;
  top: -30px;
}
.parallelograms-wrapper .parallelogram-4[data-v-43e1e238] {
  background: #023aae;
  height: 180px;
  width: 170px;
  left: 54%;
  bottom: -30px;
}
.parallelograms-wrapper .parallelogram-5[data-v-43e1e238] {
  background: #023aae;
  height: 120px;
  width: 85px;
  left: 78%;
  bottom: 60px;
}
.parallelograms-wrapper .parallelogram-6[data-v-43e1e238] {
  background: #023aae;
  height: 100px;
  width: 80px;
  left: 65%;
  bottom: 145px;
}
.parallelograms-wrapper .parallelogram-7[data-v-43e1e238] {
  background: #b1cafe;
  height: 100px;
  width: 60px;
  left: 20%;
  bottom: 80px;
}