/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.navbar[data-v-4295d220] {
  height: 100%;
  color: #fff;
  /*
      If using multiple breakpoints on on element, make sure cascade is biggest to smallest eg:
      @import '@/assets/scss/abstracts/_breakpoints.scss';

      .btn {
          background: lightblue;
          @include responsive("xl") {
              background: purple;
          }
          @include responsive("lg") {
              background: yellow;
          }
          @include responsive("md") {
              background: blue;
          }
          @include responsive("sm") {
              background: green;
          }
          @include responsive("xs") {
              background: red;
          }
      }
  */
  /*
      If using multiple breakpoints on on element, make sure cascade is biggest to smallest eg:
      @import '@/assets/scss/abstracts/_breakpoints.scss';

      .btn {
          background: lightblue;
          @include responsive("xl") {
              background: purple;
          }
          @include responsive("lg") {
              background: yellow;
          }
          @include responsive("md") {
              background: blue;
          }
          @include responsive("sm") {
              background: green;
          }
          @include responsive("xs") {
              background: red;
          }
      }
  */
}
.navbar .logo[data-v-4295d220] {
  position: relative;
  padding: 0 2rem;
  height: 6rem;
  line-height: 6rem;
  font-size: 2rem;
  margin-bottom: 2rem;
}
.navbar .logo[data-v-4295d220]::after {
  bottom: -1rem;
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #fff;
}
.navbar .nav-items-wrapper[data-v-4295d220] {
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 8rem);
  overflow-y: auto;
}
.navbar .nav-items-wrapper[data-v-4295d220]::-webkit-scrollbar-track {
  background-color: transparent;
}
.navbar .nav-item[data-v-4295d220] {
  cursor: pointer;
  padding: 1rem 2rem;
  border-left: #003061 3px solid;
}
.navbar .nav-item.active-item[data-v-4295d220] {
  background-color: rgba(145, 193, 242, 0.254);
  border-left: #e93f6f 3px solid;
}
.navbar .sub-nav[data-v-4295d220] {
  position: relative;
  padding-left: 3rem;
  font-size: 1.3rem;
}
.navbar .sub-nav[data-v-4295d220]::before {
  position: absolute;
  top: 0;
  content: "";
  width: 0.2rem;
  background-color: rgba(255, 255, 255, 0.3);
  height: 100%;
}
.navbar .sub-nav-item[data-v-4295d220] {
  padding: 1rem 0 1rem 2rem;
}
.navbar .sub-nav-item.active-item[data-v-4295d220] {
  background-color: rgba(145, 193, 242, 0.254);
  border-left: #e93f6f 3px solid;
}
.navbar .nav-divider[data-v-4295d220] {
  height: 1px;
  background: #fff;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.navbar .nav-icon[data-v-4295d220] {
  display: inline-block;
  margin-right: 1rem;
}
.navbar .dropdown-icon[data-v-4295d220] {
  transition: transform 0.3s;
  margin-left: 0.5rem;
}
.navbar .dropdown-icon.flipped[data-v-4295d220] {
  transform: rotate(180deg);
}
.navbar .cursor-default[data-v-4295d220] {
  cursor: default;
}
@media only screen and (max-width: 1399.98px) {
.navbar .nav-item[data-v-4295d220] {
    padding-left: 1rem;
    padding-right: 1rem;
}
.navbar .logo[data-v-4295d220] {
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.navbar .sub-nav[data-v-4295d220] {
    font-size: 1.1rem;
    padding-left: 1rem;
}
.navbar .sub-nav-item[data-v-4295d220] {
    padding-left: 1rem;
    height: auto;
}
}
@media only screen and (max-width: 1199.98px) {
.navbar .logo[data-v-4295d220] {
    font-size: 1.3rem;
}
.navbar .sub-nav[data-v-4295d220] {
    font-size: 1rem;
}
}