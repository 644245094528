/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.slide-in-component[data-v-5adfc372] {
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  right: 0;
  width: auto;
  height: calc(100% - 10px);
  background-color: #fff;
  z-index: 100;
  margin-top: 5px;
  margin-bottom: 5px;
  border-left: grey 1px solid;
  padding: 2rem 1rem;
}
.btn-close[data-v-5adfc372] {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 2rem;
}
.slide-enter-active[data-v-5adfc372] {
  transform: translateX(200%);
}
.slide-leave-active[data-v-5adfc372] {
  transform: translateX(100%);
}