/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.client-login-container[data-v-09266673] {
  display: grid;
  row-gap: 1rem;
  max-height: 65vh;
  overflow-y: scroll;
}
.grid[data-v-09266673] {
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  color: white;
  border-radius: 0.6rem;
  gap: 0.25rem;
}
.grid .cell[data-v-09266673] {
  background-color: #003061;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
}
.grid .cell.highlighted[data-v-09266673] {
  background-color: #00a6c9;
}
.grid .cell.warn[data-v-09266673] {
  background-color: #d2222d;
}
.grid .cell.success[data-v-09266673] {
  background-color: #238823;
}
.btn[data-v-09266673] {
  text-align: left;
  background: #003061;
  color: white;
  padding-top: 2rem;
  padding-right: 6rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  border-radius: 0.6rem;
  font-size: 2rem;
  border: 0.2rem solid #000d1a;
  border-top-width: 0.0625rem;
  border-bottom-width: 0.4625rem;
  position: relative;
  background-size: 300% 100%;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transition: all 0.4s ease-in-out;
  background-image: linear-gradient(to right, #003061, #004080, #003061, #004080);
}
.btn[data-v-09266673]:hover, .btn[data-v-09266673]:focus {
  background-position: 100% 0;
  transition: all 0.4s ease-in-out;
}
.btn[data-v-09266673]:active {
  transform: translateY(0.3rem);
  border-bottom-width: 0.1625rem;
  transition: all 0.1s ease-in-out;
}
.btn .icon[data-v-09266673] {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  opacity: 0.5;
}
.wrapper[data-v-09266673] {
  display: flex;
  gap: 1rem;
}
.wrapper .column[data-v-09266673] {
  transition: flex 300ms ease-in-out, opacity 300ms ease-in-out;
  flex: 0 0 100%;
}
.wrapper .management[data-v-09266673] {
  opacity: 0;
  flex: 0;
}
.wrapper.two-column .column[data-v-09266673] {
  flex: 1 1 50%;
}
.wrapper.two-column .management[data-v-09266673] {
  opacity: 1;
}
.wrapper--header[data-v-09266673] {
  position: sticky;
  top: 0;
  height: 0;
  overflow: hidden;
  font-size: 1.5rem;
  border-bottom: 1px solid white;
}
.wrapper--header.two-column[data-v-09266673] {
  height: 6rem;
}