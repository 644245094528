/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
div,
td,
tr,
tr,
button,
a {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.page-wrapper {
  position: relative;
  height: 100svh;
  overflow-y: auto;
}
.container {
  height: auto;
  width: 132rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}
.full-width {
  width: 100%;
}
.layout {
  height: 100%;
  padding: 4rem 0;
}
.flex, .btn--default, .btn--alternative, .btn--discard {
  display: flex;
}
.flex--column {
  flex-direction: column;
}
.flex--column.column-center {
  align-items: center;
  justify-content: center;
}
.flex--row-center, .btn--default, .btn--alternative, .btn--discard {
  align-items: center;
  justify-content: center;
}
.flex--row-space-between {
  align-items: center;
  justify-content: space-between;
}
.flex--row-space-around {
  align-items: center;
  justify-content: space-around;
}
.btn {
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
}
.btn:hover {
  opacity: 0.9;
}
.btn--login {
  width: 30rem;
  padding: 2rem;
  background-color: #002f5f;
}
.btn--default, .btn--alternative, .btn--discard {
  min-width: 10rem;
  padding: 1rem;
}
.btn--default {
  background-color: #e93f6f;
}
.btn--alternative {
  background-color: #00b0cf;
}
.btn--discard {
  background-color: #ebebeb;
  color: black;
}
.btn--disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.page-title {
  margin-bottom: 2rem;
}
.table {
  display: table;
  width: 100%;
}
.table.fixed {
  table-layout: fixed;
  width: 100%;
}
.table .tr {
  display: table-row;
  border: 1px solid white;
}
.table .table-head {
  display: table-header-group;
}
.table .th,
.table .td {
  display: table-cell;
  height: 4.4rem;
  padding: 0.5rem 1rem;
  border: 1px solid white;
}
.table .th.no-border,
.table .td.no-border {
  border-color: transparent;
}
.table .th.no-background,
.table .td.no-background {
  background-color: transparent;
}
.table .align-center {
  text-align: center;
}
.table .align-right {
  text-align: right;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.btn-wrapper {
  margin-top: 1rem;
}
.btn-wrapper .btn--discard:first-of-type {
  margin-right: 1rem;
}
.btn-wrapper .cancel {
  opacity: 0.8;
}
* {
  scrollbar-width: thin;
  scrollbar-color: #e7e7e7;
}
*::-webkit-scrollbar {
  height: 0.6rem;
  width: 0.5rem;
}
*::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border-radius: 0.5rem;
}
*::-webkit-scrollbar-thumb {
  background: #a3a3a3;
  border-radius: 0.5rem;
}
.theme-icon {
  width: 40px;
  height: 40px;
  padding: 0.7rem;
  border: 1px solid #e93f6f;
  cursor: pointer;
  border-radius: 20px;
  background-color: #e93f6f;
  font-size: 1.8rem;
  color: white;
}
.primary-button-lg {
  box-sizing: border-box;
}
.primary-button-lg .btn-content {
  background: #003061;
  color: white;
  padding-top: 2rem;
  padding-right: 6rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  margin: 1rem 0;
  border-radius: 0.6rem;
  font-size: 2rem;
  border: 0.2rem solid #000d1a;
  border-top-width: 0.0625rem;
  border-bottom-width: 0.4625rem;
  position: relative;
  background-size: 300% 100%;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transition: all 0.4s ease-in-out;
  background-image: linear-gradient(to right, #003061, #004080, #003061, #004080);
}
.primary-button-lg .btn-content.condensed {
  padding: 1rem 3rem;
  font-size: 1.4rem;
  width: 200px;
  position: absolute;
  bottom: 10rem;
}
.primary-button-lg .btn-content.condensed .icon {
  top: 1.2rem;
  font-size: 1.6rem;
}
.primary-button-lg .btn-content:hover {
  background-position: 100% 0;
  transition: all 0.4s ease-in-out;
}
.primary-button-lg .btn-content:active {
  margin-bottom: 1.3rem;
  transform: translateY(0.3rem);
  border-bottom-width: 0.1625rem;
  transition: all 0.1s ease-in-out;
}
.primary-button-lg .btn-content .icon {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  color: white;
  opacity: 0.5;
}
.link-item {
  font-weight: bold;
  text-decoration: underline;
  color: #003061;
  cursor: pointer;
}
.manager--content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0;
}
.no-manager-data {
  width: 50%;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.no-manager-data .message-text {
  margin-right: 2rem;
}
.mx-icon-left:before,
.mx-icon-right:before,
.mx-icon-double-left:before,
.mx-icon-double-right:before,
.mx-icon-double-left:after,
.mx-icon-double-right:after {
  content: "";
  position: relative;
  top: -1px;
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  border-style: solid;
  border-color: currentColor;
  border-width: 2px 0 0 2px;
  border-radius: 1px;
  box-sizing: border-box;
  transform-origin: center;
  transform: rotate(-45deg) scale(0.7);
}
.mx-icon-double-left:after {
  left: -4px;
}
.mx-icon-double-right:before {
  left: 4px;
}
.mx-icon-right:before,
.mx-icon-double-right:before,
.mx-icon-double-right:after {
  transform: rotate(135deg) scale(0.7);
}
.mx-btn {
  box-sizing: border-box;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #73879c;
  white-space: nowrap;
}
.mx-btn:hover {
  border-color: #1284e7;
  color: #1284e7;
}
.mx-btn-text {
  border: 0;
  padding: 0 4px;
  text-align: left;
  line-height: inherit;
}
.mx-scrollbar {
  height: 100%;
}
.mx-scrollbar:hover .mx-scrollbar-track {
  opacity: 1;
}
.mx-scrollbar-wrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.mx-scrollbar-track {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  width: 6px;
  z-index: 1;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.24s ease-out;
}
.mx-scrollbar-track .mx-scrollbar-thumb {
  position: absolute;
  width: 100%;
  height: 0;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(144, 147, 153, 0.3);
  transition: background-color 0.3s;
}
.mx-zoom-in-down-enter-active,
.mx-zoom-in-down-leave-active {
  opacity: 1;
  transform: scaleY(1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transform-origin: center top;
}
.mx-zoom-in-down-enter,
.mx-zoom-in-down-enter-from,
.mx-zoom-in-down-leave-to {
  opacity: 0;
  transform: scaleY(0);
}
.mx-datepicker {
  position: relative;
  display: inline-block;
  width: 210px;
}
.mx-datepicker svg {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.mx-datepicker-range {
  width: 320px;
}
.mx-datepicker-inline {
  width: auto;
}
.mx-input-wrapper {
  position: relative;
}
.mx-input-wrapper .mx-icon-clear {
  display: none;
}
.mx-input-wrapper:hover .mx-icon-clear {
  display: block;
}
.mx-input-wrapper:hover .mx-icon-clear + .mx-icon-calendar {
  display: none;
}
.mx-input {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  padding: 6px 30px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.mx-input:hover, .mx-input:focus {
  border-color: #409aff;
}
.mx-input:disabled, .mx-input.disabled {
  color: #ccc;
  background-color: #f3f3f3;
  border-color: #ccc;
  cursor: not-allowed;
}
.mx-input:focus {
  outline: none;
}
.mx-input::-ms-clear {
  display: none;
}
.mx-icon-calendar,
.mx-icon-clear {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
  vertical-align: middle;
}
.mx-icon-clear {
  cursor: pointer;
}
.mx-icon-clear:hover {
  color: rgba(0, 0, 0, 0.8);
}
.mx-datepicker-main {
  font: 14px/1.5 "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", sans-serif;
  color: #73879c;
  background-color: #fff;
  border: 1px solid #e8e8e8;
}
.mx-datepicker-popup {
  position: absolute;
  margin-top: 1px;
  margin-bottom: 1px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 2001;
}
.mx-datepicker-sidebar {
  float: left;
  box-sizing: border-box;
  width: 100px;
  padding: 6px;
  overflow: auto;
}
.mx-datepicker-sidebar + .mx-datepicker-content {
  margin-left: 100px;
  border-left: 1px solid #e8e8e8;
}
.mx-datepicker-body {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.mx-btn-shortcut {
  display: block;
  padding: 0 6px;
  line-height: 24px;
}
.mx-range-wrapper {
  display: flex;
}
@media (max-width: 750px) {
.mx-range-wrapper {
    flex-direction: column;
}
}
.mx-datepicker-header {
  padding: 6px 8px;
  border-bottom: 1px solid #e8e8e8;
}
.mx-datepicker-footer {
  padding: 6px 8px;
  text-align: right;
  border-top: 1px solid #e8e8e8;
}
.mx-calendar {
  box-sizing: border-box;
  width: 248px;
  padding: 6px 12px;
}
.mx-calendar + .mx-calendar {
  border-left: 1px solid #e8e8e8;
}
.mx-calendar-header, .mx-time-header {
  box-sizing: border-box;
  height: 34px;
  line-height: 34px;
  text-align: center;
  overflow: hidden;
}
.mx-btn-icon-left,
.mx-btn-icon-double-left {
  float: left;
}
.mx-btn-icon-right,
.mx-btn-icon-double-right {
  float: right;
}
.mx-calendar-header-label {
  font-size: 14px;
}
.mx-calendar-decade-separator {
  margin: 0 2px;
}
.mx-calendar-decade-separator:after {
  content: "~";
}
.mx-calendar-content {
  position: relative;
  height: 224px;
  box-sizing: border-box;
}
.mx-calendar-content .cell {
  cursor: pointer;
}
.mx-calendar-content .cell:hover {
  color: #73879c;
  background-color: #f3f9fe;
}
.mx-calendar-content .cell.active {
  color: #fff;
  background-color: #1284e7;
}
.mx-calendar-content .cell.in-range, .mx-calendar-content .cell.hover-in-range {
  color: #73879c;
  background-color: #dbedfb;
}
.mx-calendar-content .cell.disabled {
  cursor: not-allowed;
  color: #ccc;
  background-color: #f3f3f3;
}
.mx-calendar-week-mode .mx-date-row {
  cursor: pointer;
}
.mx-calendar-week-mode .mx-date-row:hover {
  background-color: #f3f9fe;
}
.mx-calendar-week-mode .mx-date-row.mx-active-week {
  background-color: #dbedfb;
}
.mx-calendar-week-mode .mx-date-row .cell:hover {
  color: inherit;
  background-color: transparent;
}
.mx-calendar-week-mode .mx-date-row .cell.active {
  color: inherit;
  background-color: transparent;
}
.mx-week-number {
  opacity: 0.5;
}
.mx-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
}
.mx-table th {
  padding: 0;
  font-weight: 500;
  vertical-align: middle;
}
.mx-table td {
  padding: 0;
  vertical-align: middle;
}
.mx-table-date td,
.mx-table-date th {
  height: 32px;
  font-size: 12px;
}
.mx-table-date .today {
  color: #2a90e9;
}
.mx-table-date .cell.not-current-month {
  color: #ccc;
  background: none;
}
.mx-time {
  flex: 1;
  width: 224px;
  background: #fff;
}
.mx-time + .mx-time {
  border-left: 1px solid #e8e8e8;
}
.mx-calendar-time {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mx-time-header {
  border-bottom: 1px solid #e8e8e8;
}
.mx-time-content {
  height: 224px;
  box-sizing: border-box;
  overflow: hidden;
}
.mx-time-columns {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mx-time-column {
  flex: 1;
  position: relative;
  border-left: 1px solid #e8e8e8;
  text-align: center;
}
.mx-time-column:first-child {
  border-left: 0;
}
.mx-time-column .mx-time-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mx-time-column .mx-time-list::after {
  content: "";
  display: block;
  height: 192px;
}
.mx-time-column .mx-time-item {
  cursor: pointer;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
}
.mx-time-column .mx-time-item:hover {
  color: #73879c;
  background-color: #f3f9fe;
}
.mx-time-column .mx-time-item.active {
  color: #1284e7;
  background-color: transparent;
  font-weight: 700;
}
.mx-time-column .mx-time-item.disabled {
  cursor: not-allowed;
  color: #ccc;
  background-color: #f3f3f3;
}
.mx-time-option {
  cursor: pointer;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
}
.mx-time-option:hover {
  color: #73879c;
  background-color: #f3f9fe;
}
.mx-time-option.active {
  color: #1284e7;
  background-color: transparent;
  font-weight: 700;
}
.mx-time-option.disabled {
  cursor: not-allowed;
  color: #ccc;
  background-color: #f3f3f3;
}
.mx-datepicker {
  width: 100% !important;
}

/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
html {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  scroll-behavior: smooth;
}
* {
  margin: 0;
}
body {
  background-color: #002f5f;
}
*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
button {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.6rem;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}
html {
  font-size: 10px;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
}
.no-wrap {
  white-space: nowrap;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-nowrap {
  white-space: nowrap;
}
.tooltip {
  display: block !important;
  z-index: 10000;
}
.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}
.tooltip[x-placement^=top] {
  margin-bottom: 5px;
}
.tooltip[x-placement^=top] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^=bottom] {
  margin-top: 5px;
}
.tooltip[x-placement^=bottom] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^=right] {
  margin-left: 5px;
}
.tooltip[x-placement^=right] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[x-placement^=left] {
  margin-right: 5px;
}
.tooltip[x-placement^=left] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[aria-hidden=true] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden=false] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
.tooltip.info .tooltip-inner {
  background: rgba(0, 68, 153, 0.9);
  color: white;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}
.tooltip.info .tooltip-arrow {
  border-color: rgba(0, 68, 153, 0.9);
}
.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}
.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}
.tooltip.max-width-md {
  max-width: 400px;
}

/* poppins-100 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: url(fonts/poppins-v20-latin-100.15ef609d.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-100italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  src: url(fonts/poppins-v20-latin-100italic.acd438d2.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-200 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: url(fonts/poppins-v20-latin-200.a7f7eebe.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-200italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  src: url(fonts/poppins-v20-latin-200italic.a51528d2.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url(fonts/poppins-v20-latin-300.8d91ec1c.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-300italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  src: url(fonts/poppins-v20-latin-300italic.2c12ab3a.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/poppins-v20-latin-regular.9212f6f9.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: url(fonts/poppins-v20-latin-italic.a242ba0d.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url(fonts/poppins-v20-latin-500.a09f2fcc.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-500italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: url(fonts/poppins-v20-latin-500italic.88042d51.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url(fonts/poppins-v20-latin-600.72993ddd.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-600italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  src: url(fonts/poppins-v20-latin-600italic.267ebe38.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url(fonts/poppins-v20-latin-700.25b0e113.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-700italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: url(fonts/poppins-v20-latin-700italic.858549c2.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: url(fonts/poppins-v20-latin-800.af4d371a.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-800italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  src: url(fonts/poppins-v20-latin-800italic.613d05fd.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-900 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: url(fonts/poppins-v20-latin-900.5426bf50.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-900italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  src: url(fonts/poppins-v20-latin-900italic.c6f04b95.woff2) format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
#app {
  background-color: #002f5f;
}
#app .homepage {
  display: flex;
  height: 100svh;
  padding: 5px;
}
#app .homepage .left-container {
  width: 15%;
}
#app .homepage .right-container {
  display: flex;
  flex-direction: column;
  width: 85%;
}
#app .homepage .right-container .content-area {
  flex-grow: 1;
  height: 100%;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  overflow-y: auto;
}