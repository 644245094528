/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
nav[data-v-1665b993] {
  overflow: hidden;
  white-space: nowrap;
}
nav.breadcrumb ol[data-v-1665b993] {
  margin: 0;
  padding-left: 0;
  list-style: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
nav.breadcrumb li[data-v-1665b993] {
  display: inline;
}
nav.breadcrumb li + li[data-v-1665b993]::before {
  display: inline-block;
  margin: 0 0.25rem;
  content: "/";
}
nav.breadcrumb [aria-current=page][data-v-1665b993] {
  font-weight: bold;
  color: #000;
  text-decoration: none;
  pointer-events: none;
}
nav.breadcrumb [aria-current=page][data-v-1665b993]::before {
  font-weight: normal;
}