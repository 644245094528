/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
pre[data-v-38fc7baa] {
  white-space: pre-wrap;
  padding: 1rem;
  text-align: left;
}
.modal[data-v-38fc7baa] {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal.is-active[data-v-38fc7baa] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-background[data-v-38fc7baa] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-content[data-v-38fc7baa] {
  z-index: 1;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  width: 25vw;
}
.box[data-v-38fc7baa] {
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
}
.title[data-v-38fc7baa] {
  font-size: 1.5rem;
  margin-top: 2rem;
}
.top[data-v-38fc7baa],
.bottom[data-v-38fc7baa] {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error-icon[data-v-38fc7baa] {
  font-size: 8rem;
}
.top[data-v-38fc7baa] {
  background-color: #d2222d;
  color: white;
}
.bottom[data-v-38fc7baa] {
  background-color: #fbfeff;
}
p[data-v-38fc7baa] {
  margin-bottom: 1rem;
}