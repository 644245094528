/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.name[data-v-2388edaa] {
  display: inline-block;
}
.api-btn[data-v-2388edaa] {
  padding: 1rem;
  background-color: transparent;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.2rem;
}
.api-btn[data-v-2388edaa]:hover, .api-btn[data-v-2388edaa]:focus, .api-btn[data-v-2388edaa]:active {
  background-color: #f8f9fa;
}
.success[data-v-2388edaa] {
  color: #238823;
}
.error[data-v-2388edaa] {
  color: #d2222d;
}
.apis[data-v-2388edaa] {
  display: grid;
  grid-gap: 2rem;
}