/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.login[data-v-26084dc2] {
  display: flex;
  min-height: 100svh;
  overflow: hidden;
}
.login .left[data-v-26084dc2], .login .right[data-v-26084dc2] {
  width: 50%;
  height: 100svh;
}
.login .right[data-v-26084dc2] {
  background-image: url("/imgs/chevron-bg.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background: #003061;
  color: #fff;
  position: relative;
}
@media (max-width: 991.98px) {
.login .right[data-v-26084dc2] {
    display: none;
}
}
.login .right .innerText[data-v-26084dc2] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 350px;
}
.login .right .innerText p[data-v-26084dc2] {
  margin-top: 1rem;
}
.login .right .innerText .heading[data-v-26084dc2] {
  font-size: 3rem;
  font-weight: 300;
}
.login .left[data-v-26084dc2] {
  background-color: #fff;
  padding: 5rem;
  position: relative;
}
.login .left .login-action[data-v-26084dc2] {
  margin-top: 4rem;
}
@media (max-width: 991.98px) {
.login .left[data-v-26084dc2] {
    height: 100svh;
    padding: 2rem !important;
    width: 100% !important;
}
}
.login .left .logo[data-v-26084dc2] {
  margin-bottom: 1rem;
}
.login .left .logo img[data-v-26084dc2] {
  height: 5rem;
}