/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.top-bar[data-v-ae7504a4] {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  width: 100%;
  height: 6rem;
  border-bottom: 1px solid #002f5f;
  line-height: 6rem;
  padding: 0 2rem;
  border-radius: 5px 5px 0 0;
  background-color: #fff;
}