/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
p[data-v-79ebc047] {
  font-size: 1.2rem;
  line-height: 1.8rem;
}
.success[data-v-79ebc047] {
  color: #238823;
}
.error[data-v-79ebc047] {
  color: #d2222d;
}
.description[data-v-79ebc047] {
  margin-left: 1rem;
  padding-left: 0.5rem;
  border-left: 3px solid #e93f6f;
}