/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.loading-overlay[data-v-681413a4] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(245, 245, 245, 0.986);
  z-index: 4;
}
.loading-overlay .loading[data-v-681413a4] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loading-overlay .loading .spinner-icon[data-v-681413a4] {
  display: block;
  font-size: 5rem;
}