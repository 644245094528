/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
.mt-5[data-v-5f002905] {
  margin-top: 5rem;
}
.client[data-v-5f002905] {
  display: flex;
  overflow: hidden;
}
.client .left[data-v-5f002905],
.client .right[data-v-5f002905] {
  flex: 1;
  height: 100svh;
  transition: flex-basis 1s;
}
.client .left[data-v-5f002905] {
  background-color: #fff;
  position: relative;
}
.client .left.expanded[data-v-5f002905] {
  flex-basis: 80%;
}
.client .left.expanded + .right[data-v-5f002905] {
  flex-basis: auto;
}
.client .left.expanded + .right .inner-text[data-v-5f002905] {
  opacity: 0;
}
.client .left .client-holder[data-v-5f002905] {
  margin-bottom: 6rem;
}
.client .left .client-holder .client-name[data-v-5f002905] {
  display: flex;
  margin-top: 4rem;
  max-height: 65vh;
  overflow-y: scroll;
  position: relative;
}
.client .left .client-holder .client-name .client-list[data-v-5f002905] {
  margin-top: 0;
  max-width: 800px;
  transition: margin-top 1s;
  width: calc(50vw - 100px);
}
.client .left .client-holder .client-name .client-list .btn-content[data-v-5f002905] {
  height: calc(85px - 1rem);
}
.client .left .client-holder .client-name .client-list.margin[data-v-5f002905] {
  margin-top: 5rem;
}
.client .left .client-holder .client-name .client-list .small[data-v-5f002905] {
  font-size: 16px;
}
.client .left .client-holder .client-holder-header[data-v-5f002905] {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.client .left .client-holder .search-wrapper[data-v-5f002905] {
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  flex: 1;
}
.client .left .client-holder input[data-v-5f002905] {
  flex: 0 0 300px;
  border: 1px solid #003061;
  border-radius: 4px;
  padding: 1rem;
}
.client .left .open-management[data-v-5f002905] {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}
.client .left .open-management button[data-v-5f002905] {
  color: #003061;
  font-size: 3rem;
}
.client .left .open-management button[data-v-5f002905]:disabled {
  cursor: not-allowed;
}
.client .left .open-management .loader[data-v-5f002905] {
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 3rem;
}
.client .left .open-management .loader.show[data-v-5f002905] {
  opacity: 1;
}
.client .right[data-v-5f002905] {
  background: #003061;
  color: #fff;
  background-image: url("/imgs/chevron-bg.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  position: relative;
}
@media (max-width: 991.98px) {
.client .right[data-v-5f002905] {
    display: none;
}
}
.client .right .inner-text[data-v-5f002905] {
  transition: opacity 500ms;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 350px;
}
.client .right .inner-text .heading[data-v-5f002905] {
  font-size: 3rem;
  font-weight: 300;
}
.client .left[data-v-5f002905] {
  padding: 5rem;
  overflow-y: auto;
  position: relative;
}
@media (max-width: 991.98px) {
.client .left[data-v-5f002905] {
    width: 100% !important;
    height: 100svh;
    padding: 2rem !important;
}
}
.client .left .absolute[data-v-5f002905] {
  position: absolute;
}
.client .left .logo[data-v-5f002905] {
  margin-bottom: 1rem;
}
.client .left .logo img[data-v-5f002905] {
  height: 5rem;
}
.client .left .register[data-v-5f002905] {
  margin: 1rem;
  text-decoration: underline;
  cursor: pointer;
}
.client .left .footer[data-v-5f002905] {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 7rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
}
.client .left .footer a[data-v-5f002905] {
  text-decoration: underline;
}
.client .left .management-table-wrapper[data-v-5f002905] {
  color: #eee;
  left: 45vw;
  position: absolute;
  transition: opacity 0.3s;
  display: none;
}
.client .left .management-table-wrapper.show[data-v-5f002905] {
  display: block;
  opacity: 1;
}